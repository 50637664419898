/** Vue Font Awesome component: https://github.com/FortAwesome/vue-fontawesome */
import Vue from 'vue';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faCircleCheck as fasCircleCheck,
    faCircleStar as fasCircleStar,
    faSquareArrowLeft as fasSquareArrowLeft,
    faGrid as fasGrid,
    faAngleDown as fasAngleDown,
    faSquare1 as fasSquare1,
    faSquare2 as fasSquare2,
    faSquare3 as fasSquare3,
    faRotateLeft as faRotateLeft,
    faXmark as fasXmark,
    faSearch as fasSearch,
    faLock as fasLock,
    faLightbulbOn as fasLightbulbOn,
    faSquareDashed as fasSquareDashed,
    faChalkboard as fasChalkboard,
    faScreenUsers as fasScreenUsers,
    faGamepadModern as fasGamepadModern,
    faRobot as fasRobot,
    faPlay as fasPlay,
    faRotate as fasRotate,
    faCode as fasCode,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faCheck as farCheck,
    faCircle as farCircle,
    faCircleDashed as farCircleDashed,
    faCircleQuestion as farCircleQuestion,
    faDisplayCode as farDisplayCode,
    faPalette as farPalette,
    faFolderImage as farFolderImage,
    faAngleLeft as farAngleLeft,
    faAngleRight as farAngleRight,
    faHandPointer as farHandPointer,
    faCommentsQuestionCheck as farCommentsQuestionCheck,
    faSearch as farSearch,
    faPaperPlane as farPaperPlane,
    faArrowRight as farArrowRight,
    faBoxOpen as farBoxOpen,
    faRectangleCode as farRectangleCode,
    faSave as farSave,
    faBook as farBook,
    faFolderOpen as farFolderOpen,
    faBookOpenCover as farBookOpenCover,
    faClipboardList as farClipboardList,
    faRotate as farRotate,
    faPuzzlePiece as farPuzzlePiece,
    faKeyboard as farKeyboard,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faUserCircle as fadUserCircle,
    faCircleCheck as fadCircleCheck,
    faCircleArrowRight as fadCircleArrowRight,
    faBookOpenCover as fadBookOpenCover,
    faSpinner as fadSpinner,
    faCircleXmark as fadCircleXmark,
    faSquareCheck as fadSquareCheck,
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faCircleCheck as falCircleCheck,
    faCircleDashed as falCircleDashed,
} from '@fortawesome/pro-light-svg-icons';

config.autoAddCss = false;

// Add icons using this plugin
const solid = [
    fasCircleCheck,
    faRotateLeft,
    fasCircleStar,
    fasGrid,
    fasSquareArrowLeft,
    fasAngleDown,
    fasSquare1,
    fasSquare2,
    fasSquare3,
    fasXmark,
    fasSearch,
    fasLock,
    fasLightbulbOn,
    fasSquareDashed,
    fasChalkboard,
    fasScreenUsers,
    fasGamepadModern,
    fasRobot,
    fasPlay,
    fasRotate,
    fasCode,
];
const regular = [
    farCheck,
    farCircle,
    farCircleQuestion,
    farDisplayCode,
    farPalette,
    farFolderImage,
    farAngleLeft,
    farAngleRight,
    farHandPointer,
    farCommentsQuestionCheck,
    farSearch,
    farPaperPlane,
    farArrowRight,
    farBoxOpen,
    farRectangleCode,
    farSave,
    farBook,
    farFolderOpen,
    farBookOpenCover,
    farClipboardList,
    farRotate,
    farPuzzlePiece,
    farKeyboard,
];
const duotone = [
    fadUserCircle,
    fadCircleCheck,
    fadCircleArrowRight,
    fadBookOpenCover,
    fadSpinner,
    fadCircleXmark,
    fadSquareCheck,
];
const light = [
    falCircleCheck,
    falCircleDashed,
];

library.add(...solid, ...regular, ...duotone, ...light);

Vue.component('fa', FontAwesomeIcon);